var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Jumbotron',{attrs:{"title":_vm.$t('pages.customer.messages.OwnerMessageIndex.title')}}),_c('DataTable',{attrs:{"items":_vm.messages,"fields":[
      {
        key: 'isSent',
        label: _vm.$t('tables.messages.isSent'),
        sorter: false,
        filter: false,
      },
      {
        key: 'createdAt',
        label: _vm.$t('tables.messages.createdAt'),
        sorter: false,
        filter: false,
      },
      {
        key: 'subject',
        label: _vm.$t('tables.messages.subject'),
        sorter: false,
        filter: false,
      },
      'status',
      {
        key: 'sender',
        label: _vm.$t('tables.messages.sender'),
        sorter: false,
        filter: false,
      },
      {
        key: 'recipient',
        label: _vm.$t('tables.messages.recipient'),
        sorter: false,
        filter: false,
      },
      {
        key: 'actions',
        label: _vm.$t('tables.common.actions'),
        sorter: false,
        filter: false,
      } ],"table-filter-value":_vm.tableFilterValue,"sorter-value":_vm.sorterValue,"loading":_vm.loading},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event},"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"isSent",fn:function(ref){
    var item = ref.item;
return [_c('td',[(item.isSent)?_c('div',[_vm._v("Gesendet")]):_vm._e(),(!item.isSent)?_c('div',[_vm._v("Empfangen")]):_vm._e()])]}},{key:"subject",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{
            name: 'OwnerMessageDetail',
            params: { messageId: item.id },
          }}},[_vm._v(" "+_vm._s(item.subject)+" ")])],1)]}},{key:"status",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('MessageStatus',{attrs:{"message":item}})],1)]}},{key:"createdAt",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('FormatedTimestamp',{attrs:{"date":_vm.parseDate(item.createdAt),"displayFormat":"P | p"}})],1)]}},{key:"actions",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('router-link',{directives:[{name:"t",rawName:"v-t",value:('pages.customer.messages.OwnerMessageIndex.open'),expression:"'pages.customer.messages.OwnerMessageIndex.open'"}],attrs:{"to":{
            name: 'OwnerMessageDetail',
            params: { messageId: item.id },
          }}}),(_vm.canAnswer(item) && !item.senderIsDeleted)?_c('span',[_vm._v(" | "),_c('router-link',{directives:[{name:"t",rawName:"v-t",value:('pages.customer.messages.OwnerMessageIndex.answer'),expression:"'pages.customer.messages.OwnerMessageIndex.answer'"}],attrs:{"to":{
              name: 'OwnerCreateMessage',
              params: { receiverId: item.senderId, orgSubject: item.subject },
            }}})],1):_vm._e(),(_vm.canAnswer(item) && item.senderIsDeleted)?_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            appendToBody: true,
            content: _vm.$t('pages.owner.messages.OwnerMessageIndex.tooltipGuestIsDeleted'),
          }),expression:"{\n            appendToBody: true,\n            content: $t('pages.owner.messages.OwnerMessageIndex.tooltipGuestIsDeleted'),\n          }"}]},[_vm._v(" | "+_vm._s(_vm.$t('pages.customer.messages.OwnerMessageIndex.answer'))+" ")]):_vm._e(),_c('span',[_vm._v(" | "),_c('a',{directives:[{name:"t",rawName:"v-t",value:('pages.customer.messages.OwnerMessageIndex.delete'),expression:"'pages.customer.messages.OwnerMessageIndex.delete'"}],attrs:{"href":"#","data-cy":"delete-message"},on:{"click":function($event){$event.preventDefault();return _vm.deleteMessage(item.id)}}})])],1)]}}])}),(_vm.pages > 1)?_c('CPagination',{attrs:{"pages":_vm.pages,"activePage":_vm.currentPage},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}}):_vm._e(),_c('DeleteConfirmationModal',{attrs:{"show":_vm.showDeleteModal,"message":_vm.$t('pages.owner.messages.OwnerMessageIndex.deleteMessage')},on:{"delete":_vm.internalOnDelete,"close":_vm.closeDeleteModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }