
































































































































import OwnerMessageService from '@/services/owner/OwnerMessageService';
import MessageStatus from '@/components/messages/MessageStatus.vue';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import Jumbotron from '@/components/shared/Jumbotron.vue';
import Vue, { VueConstructor } from 'vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';
import { MetaInfo } from 'vue-meta';

class OwnerMessageIndexDto {
  id!: string;
  senderId!: string;
  senderIsDeleted!: boolean;
  isSent!: boolean;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: {
    MessageStatus,
    FormatedTimestamp,
    DataTable,
    Jumbotron,
    DeleteConfirmationModal,
  },
  mixins: [FormaterMixin],
  data() {
    return {
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'CreatedAt', asc: false },
      currentPage: 1,
      loading: false,
      messages: [] as OwnerMessageIndexDto[],
      deleteMessageId: '',
      showDeleteModal: false,
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostMessageIndex.meta.title').toString(),
    };
  },
  methods: {
    canAnswer(message: OwnerMessageIndexDto) {
      return message.senderId != null && !message.isSent;
    },

    parseDate(dateString: string): Date | null {
      return this.parseIsoDateTimeTz(dateString);
    },

    deleteMessage(messageId: string): void {
      this.showDeleteModal = true;
      this.deleteMessageId = messageId;
    },

    internalOnDelete(): void {
      OwnerMessageService.delete(this.deleteMessageId).then(() => {
        this.loadData();
        this.showDeleteModal = false;
      });
    },

    loadData() {
      this.loading = true;
      OwnerMessageService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.messages = res.value.messages.items;
        this.pages = res.value.messages.totalPages;
      });
    },

    closeDeleteModal() {
      this.showDeleteModal = false;
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostMessageIndex.breadcrumb.last' },
      ]);
    },
  },
  mounted() {
    // redirect if not logged in on Mounted
    const loggedIn = this.$store.state.AuthStoreModule.status.loggedIn;
    if (!loggedIn) {
      this.$router.push(process.env.VUE_APP_PUBLIC_BASE_URL + '/auth/relogin?redirect=' + this.$route.fullPath);
    } else {
      this.loadData();
      this.setBreadCrumb();
    }
  },
});
