import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/Messages/';

class OwnerMessageService {
  Create(message: any, receiverId: string) {
    return ApiService.post(`${API_URL}Create`, {
      ...message,
      receiverId: receiverId,
    });
  }

  delete(id: string) {
    return ApiService.delete(`${API_URL}Delete?id=${id}`);
  }

  forCreate(receiverId: string) {
    return ApiService.get(`${API_URL}Create?receiverId=${receiverId}`);
  }

  getDetail(messageId: string) {
    return ApiService.get(`${API_URL}Detail?messageId=${messageId}`);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new OwnerMessageService();
