






import Vue, { VueConstructor } from 'vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  props: {
    date: {
      type: [String, Date],
      default: '',
    },
    displayFormat: {
      type: String,
      default: 'P | p',
    },
    tooltipFormat: {
      type: String,
      default: 'PPPP',
    },
    showDistance: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formatDistance(): string {
      return this.formatIsoDateTimeDistanceTz(this.date);
    },
    tooltipDate(): string {
      return this.formatIsoDateTimeTz(this.date, this.tooltipFormat);
    },
    displayDate(): string {
      return this.formatIsoDateTimeTz(this.date, this.displayFormat);
    },
  },
});
